<template>
  <div class="wrapper fadeIn">
    <head-component/>
    <section class="for-whom-block">
        <div v-if="certificates.length > 0">
          <h3>Выданные сертификаты</h3>
          <ul class="list-unstyled file-list">
            <li v-for="(cert, key) in certificates" :key="key">
              <a style="color: #008C8C" target="_blank" :href="'https://api.universarium.org/cert/' + cert.id">
                <i class="fa fa-certificate"></i> {{ cert.name }}</a>
            </li>
          </ul>
        </div>
    </section>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import HeadComponent from "../components/HeadComponent";

export default {
  name: "Certificate",
  components: {HeadComponent},
  data() {
    return {
      certificates: [],
    }
  },
  mounted() {
    ApiService
        .get('certificates/my', {
          params: {
            isKids: true
          }
        })
        .then(res => {
          this.certificates = res.data;
        });
  }
}
</script>

<style scoped>

</style>